import { Box } from "@mui/material";
import styled from "styled-components";

export const BoxCustom= styled(Box)`
    @media (max-width: 540px){
        flex-direction: row;
    }
`
export const BoxItem = styled(Box)`
    @media (max-width: 540px){
        width: 170px;
        justify-content: space-between !important;
        .MuiTypography-h6{
            text-align: center
        }
    }
`