import React, { useEffect, useMemo } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import { useCallback } from 'react'
import { useRef } from 'react'
import upload from 'apis/upload'

const WrappedCk = styled('div')`
    width: 100%;

    .ck-content {
        height: 300px;
    }
`

function CkEditorCustomized({ data, getDataProps }) {
    const [editorData, setEditorData] = React.useState(data ?? '')
    const quillRef = useRef()
    const handleEditorChange = (event, editor) => {
        // const newData = editor.getData()
        setEditorData(event)

        // Call the parent component's callback function to pass the data
        getDataProps && getDataProps(event)
    }

    useEffect(() => {
        data !== undefined && setEditorData(data.replace(/&quot;/g, '"'))
    }, [data])

    const imageHandler = useCallback((e) => {
        const editor = quillRef.current.getEditor()

        // eslint-disable-next-line no-undef
        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.click()

        input.onchange = async () => {
            const file = input.files[0]
            if (/^image\//.test(file.type)) {
                const formData = new FormData()
                formData.append('file', file)
                formData.append('path', 'data/agent-d1')
                const res = await upload.uploadFile(formData)
                console.log(res)
                // const url =
                //     `https://d3-api.evenethence.com/storage/data/d1/` +
                //     res?.data
                editor.insertEmbed(editor.getSelection(), 'image', res?.data)
            } else {
                // ErrorToast('You could only upload images.')
            }
        }
    })

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ font: [] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    [{ script: 'sub' }, { script: 'super' }],
                    ['blockquote', 'code-block'],
                    [{ list: 'ordered' }, { list: 'bullet' }],

                    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
                    [{ direction: 'rtl' }],
                    [{ size: ['small', false, 'large', 'huge'] }],
                    ['link', 'image', 'video'],
                    ['clean']
                ],

                // handlers: {
                //     image: handleClick
                // },
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true
                }
            }
        }),
        []
    )
    useEffect(() => {
        quillRef.current
            ?.getEditor()
            .getModule('toolbar')
            .addHandler('image', imageHandler)
    }, [imageHandler])

    return (
        <WrappedCk>
            <ReactQuill
                style={{
                    height: 250,
                    marginBottom: 20
                }}
                value={editorData}
                onChange={handleEditorChange}
                theme='snow'
                modules={modules}
                ref={quillRef}
            />
            {/* <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onReady={(editor) => {}}
                onInit={(editor) => {
                    // editor.ui.view.editable.extendTemplate({
                    //   toolbar: [
                    //     {
                    //       name: 'basicstyles',
                    //       items: [
                    //         'Bold',
                    //         'Italic',
                    //         'Underline',
                    //         'Strikethrough , FontBackgroundColor'
                    //       ]
                    //     },
                    //     { name: 'list', items: ['BulletedList', 'NumberedList'] },
                    //     { name: 'paragraph', items: ['BlockQuote'] }
                    //   ]
                    // })
                }}
                onChange={handleEditorChange}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
            /> */}
        </WrappedCk>
    )
}

CkEditorCustomized.propTypes = {
    getDataProps: PropTypes.func,
    data: PropTypes.string
}

export default CkEditorCustomized
