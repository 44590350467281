import React from 'react'
import Button from '@mui/material/Button'

function ButtonCustom({ sx, title, bgcolor, colorText, onClick }) {
    return (
        <Button
            onClick={onClick}
            sx={{ padding: '5px 10px', backgroundColor: bgcolor }}
            variant='contained'
            style={sx}
        >
            {title}
        </Button>
    )
}

export default ButtonCustom
