import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleMiddlewareLoadingAction } from 'store/helper';
import messageApi from 'apis/message';

const getMessageData = createAsyncThunk(
    'get/message',
    (query) => handleMiddlewareLoadingAction(() => messageApi.getMessage(query), 'get/message')
)
const getMessageDetail = createAsyncThunk(
    'get/messageDetail',
    (query) => handleMiddlewareLoadingAction(() => messageApi.getMessageDetail(query), 'get/messageDetail')
)
const deleteMessage = createAsyncThunk(
    'delete/message',
    (id) => handleMiddlewareLoadingAction(() => messageApi.deleteMessage(id), 'delete/message')
)

const messageSlice = createSlice({
    name: 'agentNoteList',
    initialState:{
        messageList: undefined,
        messageDetail: undefined
    },
    reducers: {
        clearDetail: (state) =>{
            state.messageDetail = undefined
        },
    },
    extraReducers: {
        [getMessageData.fulfilled]: (state, action) => {
            state.messageList = action.payload
        },
        [getMessageDetail.fulfilled]: (state, action) => {
                state.messageDetail = action.payload
        }
    }
})

export const thunkActions = {
    getMessageData,
    getMessageDetail,
    deleteMessage
}

export const actions = messageSlice.actions

export default messageSlice.reducer
