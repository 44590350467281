// import styled from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

export const TableHeadCustom = styled(TableCell)`
    @media(max-width: 540px){
        width: max-content !important;
        min-width: max-content !important;
        padding: 10px;
        font-size: 0.775rem;
        white-space: nowrap;
        text-align: center;
    }
`;
export const TableBodyCustom = styled(TableCell)`
    @media(max-width: 540px){
        font-size: 0.775rem;
        // white-space: nowrap;
        text-align: center;
    }
`
