import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import agentApi from 'apis/agent'
import { handleMiddlewareLoadingAction } from 'store/helper'

const initialState = {
    dataAgent: [],
    header: null,
    detailAgent: null,
    isLoading: false
}

export const getAgents = createAsyncThunk('getListingAgents', () => {
    const res = handleMiddlewareLoadingAction(
        () => agentApi.getListing(),
        'getListingAgents'
    )
    return res
})

export const createAgent = createAsyncThunk(
    'createAgent',
    ({ data, params }) => {
        const res = handleMiddlewareLoadingAction(
            () => agentApi.createAgent(data, params),
            'createAgent'
        )
        return res
    }
)

export const checkIdAgent = createAsyncThunk('checkIdAgent', (params) => {
    const res = handleMiddlewareLoadingAction(
        () => agentApi.checkIdAgent(params),
        'checkIdAgent'
    )
    return res
})

export const checkNameAgent = createAsyncThunk('checkNameAgent', (params) => {
    const res = handleMiddlewareLoadingAction(
        () => agentApi.checkNameAgent(params),
        'checkNameAgent'
    )
    return res
})

export const getDetailAgent = createAsyncThunk('getDetailAgent', (params) => {
    const res = handleMiddlewareLoadingAction(
        () => agentApi.getDetailAgent(params),
        'getDetailAgent'
    )
    return res
})

export const postRolling = createAsyncThunk(
    'postRolling',
    ({ data, params }) => {
        const res = handleMiddlewareLoadingAction(
            () => agentApi.postRolling(data, params),
            'postRolling'
        )
        return res
    }
)

const agentSlice = createSlice({
    name: 'agent',
    initialState: initialState,
    extraReducers: {
        [getAgents.fulfilled]: (state, action) => {
            state.dataAgent = action.payload?.list || []
            state.header = action.payload?.header || []
        },
        [getDetailAgent.pending]: (state, action) => {
            state.isLoading = true
        },
        [getDetailAgent.fulfilled]: (state, action) => {
            state.detailAgent = action.payload?.result || []
            state.isLoading = false
        }
    }
})

const agentSliceReducer = agentSlice.reducer
export default agentSliceReducer
