import http from 'views/utilities/http'
const URL = '/agents'
const URL_C = '/agent/input/'
const URL_ID_CHECK = '/user/idcheck'
const URL_NAME_CHECK = '/user/namecheck'
const URL_DETAIL = '/member/detail/'
const URL_POST_ROLLING = 'member/change/'
const agentApi = {
    getListing: () => http.get(URL),
    createAgent: (data, params) => http.post(URL_C + params.mb_no, data),
    checkIdAgent: (params) => http.get(URL_ID_CHECK, { params: params }),
    checkNameAgent: (params) => http.get(URL_NAME_CHECK, { params: params }),
    getDetailAgent: (params) => http.get(URL_DETAIL + params?.mb_no),
    postRolling: (data, params) =>
        http.post(URL_POST_ROLLING + params?.mb_no, data)
}

export default agentApi
