import React, { useState, useMemo } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToastControl } from 'views/utilities/toastControl'
import {
    getDeposit,
    getIP,
    getMessage
} from 'views/pages/UserHistory/userHistory.slice'
import { formatDate } from 'views/utilities/formatDate'
import MainCard from 'ui-component/cards/MainCard'
import dayjs from 'dayjs'
import DateRangeCustomzied from 'ui-component/DateRangeCustomized'
import ButtonSearch from 'ui-component/Buttons/ButtonSearch'
import settlementApi from 'apis/settlement'
import { actions as loaderActions } from 'store/loader.slice'
import StickyHeadTable from 'ui-component/Table'
import ButtonCustom from 'ui-component/button/ButtonCustom'
import axios from 'axios'
import { fullUrlHttp } from 'views/utilities/http'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        minWidth: '900px',
        maxWidth: '1000px'
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}))

// eslint-disable-next-line react/prop-types
export default function Settlement({ open, setOpen }) {
    const today = dayjs()
    const dispatch = useDispatch()
    const [data, setData] = useState(undefined)
    const loader = useSelector((s) => s.loader)
    const [page, setPage] = useState(1)
    const { toastControl } = useToastControl()
    const [valueDatePicker, setValueDatePicker] = useState({
        startDate: today,
        endDate: today
    })
    const handleClose = () => {
        setOpen(false)
    }
    const onChangeEndDatePicker = (endDayValue) => {
        setValueDatePicker((prev) => ({ ...prev, endDate: dayjs(endDayValue) }))
    }
    useEffect(() => {
        if (open) {
            getData()
        }
        return () => {
            setValueDatePicker((p) => ({ endDate: today, startDate: today }))
        }
    }, [open])

    const getData = async (query) => {
        const initialQuery = {
            pnum: 10,
            page,
            sdate: dayjs(valueDatePicker.startDate).format('YYYY-MM-DD'),
            edate: dayjs(valueDatePicker.endDate).format('YYYY-MM-DD'),
            ...query
        }
        dispatch(loaderActions.startLoad('settlement'))
        settlementApi.getSettlement(initialQuery).then((res) => {
            setData(res)
            dispatch(loaderActions.endLoad('settlement'))
        })
    }

    const onChangeStartDatePicker = (startDayValue) => {
        setValueDatePicker((prev) => ({
            ...prev,
            startDate: dayjs(startDayValue)
        }))
    }

    const handlePageChange = (p) => {
        getData({ page: p })
        setPage(p)
    }

    const handleFilter = () => {
        setPage(1)
        getData()
    }

    const columns = useMemo(() => {
        return Object.keys(data?.header || {}).map((e) => {
            return {
                id: e,
                label: data?.header[e],
                minWidth: 170
                // render: (v) => {
                //     switch (e) {
                //         case 'bet':
                //             return <>{formatMoney(v)}</>;
                //         case 'win':
                //             return <>{formatMoney(v)}</>;
                //         default:
                //             return <>{v}</>;
                //     }
                // }
            }
        })
    }, [data?.header])

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby='responsive-dialog-title'
                open={open}
            >
                <Typography
                    variant='h3'
                    sx={{ m: 0, p: 2 }}
                    id='customized-dialog-title'
                >
                    정산금
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ padding: '16px', minWidth: '100%' }}>
                    <MainCard>
                        <Grid container alignItems={'center'}>
                            <Grid item>
                                <DateRangeCustomzied
                                    onChangeStartDate={onChangeStartDatePicker}
                                    onChangeEndDate={onChangeEndDatePicker}
                                    startDate={valueDatePicker.startDate}
                                    endDate={valueDatePicker.endDate}
                                    disabled={loader.loader['settlement']}
                                />
                            </Grid>
                            <Grid item>
                                <ButtonSearch
                                    onClick={() => handleFilter()}
                                    disabled={loader.loader['settlement']}
                                >
                                    검색
                                </ButtonSearch>
                            </Grid>
                        </Grid>
                        <Grid container gap={5} mt={2}>
                            <Grid item>
                                <Typography
                                    variant='h3'
                                    id='customized-dialog-title'
                                >
                                    정산금 :{' '}
                                    <span style={{ color: 'red' }}>
                                        {data?.head.available_amount || 0}
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant='h3'
                                    id='customized-dialog-title'
                                >
                                    음수 정산금 :{' '}
                                    <span style={{ color: 'blue' }}>
                                        {data?.head
                                            .negative_settlement_balance || 0}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* 음수 정산금: 0 정산금: 0 */}
                    </MainCard>
                    <MainCard sx={{ marginTop: 2 }}>
                        <StickyHeadTable
                            currentPage={page}
                            disabled={loader.loader['settlement']}
                            paginate
                            pageChange={handlePageChange}
                            dataTable={{
                                columns,
                                rows: data?.list.data,
                                total: data?.list.last_page
                            }}
                        />
                    </MainCard>
                    {!data?.action.is_hidden && (
                        <ButtonCustom
                            sx={{ marginTop: 10 }}
                            title={data?.action?.label || ''}
                            onClick={() => {
                                fullUrlHttp
                                    .get(data?.action.link)
                                    .then(({ data }) => {
                                        toastControl(
                                            true,
                                            'success',
                                            data.message
                                        )
                                    })
                                    .catch((err) => {
                                        toastControl(true, 'error', err.message)
                                    })
                            }}
                        />
                    )}
                </Box>
            </BootstrapDialog>
        </>
    )
}
