import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import { path } from 'constants/path'
import Losing from 'views/pages/Losing/Losing'
import { Navigate } from 'react-router-dom'

const UserInfo = Loadable(lazy(() => import('views/pages/UserInfor')))

const PointChangeList = Loadable(
    lazy(() => import('views/pages/PointChangeList'))
)
const UserConnector = Loadable(lazy(() => import('views/pages/UserConnector')))
const UserList = Loadable(lazy(() => import('views/pages/UserList')))
// Game history statistics
const SlotHistory = Loadable(lazy(() => import('views/pages/history/Slot')))
const LiveHistory = Loadable(lazy(() => import('views/pages/history/Live')))
const UserHistory = Loadable(lazy(() => import('views/pages/history/User')))
const ShopHistory = Loadable(lazy(() => import('views/pages/history/Shop')))
const AllGameHistory = Loadable(
    lazy(() => import('views/pages/history/AllGame'))
)
const AllUserHistory = Loadable(
    lazy(() => import('views/pages/history/AllUser'))
)

const UserDetailHistory = Loadable(
    lazy(() => import('views/pages/UserHistory/UserHistory'))
)
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/pages/Dashboard')))

// utilities routing
const UtilsTypography = Loadable(
    lazy(() => import('views/utilities/Typography'))
)
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')))
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')))
const UtilsMaterialIcons = Loadable(
    lazy(() => import('views/utilities/MaterialIcons'))
)
const UtilsTablerIcons = Loadable(
    lazy(() => import('views/utilities/TablerIcons'))
)
const BettingSlot = Loadable(
    lazy(() => import('views/pages/Betting&Rolling/BettingSlot/BettingSlot'))
)
const BettingCasino = Loadable(
    lazy(() =>
        import('views/pages/Betting&Rolling/BettingCasino/BettingCasino')
    )
)
const SlotandCasino = Loadable(
    lazy(() => import('views/pages/Betting&Rolling/Slot&Casino/SlotAndCasino'))
)
const AgentNote = Loadable(lazy(() => import('views/pages/1-1History')))
// betting management
const ManagementBettingAll = Loadable(
    lazy(() => import('views/pages/managementBetting/All'))
)
const ManagementBettingWin = Loadable(
    lazy(() => import('views/pages/managementBetting/Win'))
)
const ManagementBettingLose = Loadable(
    lazy(() => import('views/pages/managementBetting/Lose'))
)
const ManagementBettingCancel = Loadable(
    lazy(() => import('views/pages/managementBetting/Cancel'))
)
const ManagementBettingCasino = Loadable(
    lazy(() => import('views/pages/managementBetting/Casino'))
)
const ManagementBettingSlot = Loadable(
    lazy(() => import('views/pages/managementBetting/Slot'))
)
const Message = Loadable(lazy(() => import('views/pages/Message')))
const Agent = Loadable(lazy(() => import('views/pages/Agent')))

// sample page routing

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: path.home,
            element: <Navigate to={path.dashboard} />
        },
        {
            path: path.userHistory,
            element: <UserDetailHistory />
        },
        {
            path: path.dashboard,
            element: <DashboardDefault />
        },
        {
            path: path.userList,
            element: <UserList />
        },
        {
            path: path.userConnector,
            element: <UserConnector />
        },
        {
            path: path.userConnector,
            element: <DashboardDefault />
        },
        {
            path: path.userRollingConversion,
            element: <PointChangeList />
        },
        {
            path: path.historySlot,
            element: <SlotHistory />
        },
        {
            path: path.historyLive,
            element: <LiveHistory />
        },
        {
            path: path.historyUser,
            element: <UserHistory />
        },
        {
            path: path.historyShop,
            element: <ShopHistory />
        },
        {
            path: path.historyAllGame,
            element: <AllGameHistory />
        },
        {
            path: path.historyAllUser,
            element: <AllUserHistory />
        },
        {
            path: path.managementBettingAll,
            element: <ManagementBettingAll />
        },
        {
            path: path.managementBettingWin,
            element: <ManagementBettingWin />
        },
        {
            path: path.managementBettingLose,
            element: <ManagementBettingLose />
        },
        {
            path: path.managementBettingCancel,
            element: <ManagementBettingCancel />
        },
        {
            path: path.managementBettingCasino,
            element: <ManagementBettingCasino />
        },
        {
            path: path.managementBettingSlot,
            element: <ManagementBettingSlot />
        },
        {
            path: path.userInfo,
            element: <UserInfo />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/betting/slot',
            element: <BettingSlot />
        },
        {
            path: '/betting/casino',
            element: <BettingCasino />
        },
        {
            path: '/betting/slot&casino',
            element: <SlotandCasino />
        },
        {
            path: '/losing',
            element: <Losing />
        },
        {
            path: path.agentNote,
            element: <AgentNote />
        },
        {
            path: path.message,
            element: <Message />
        },
        {
            path: path.agent,
            element: <Agent />
        }
    ]
}

export default MainRoutes
