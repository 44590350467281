import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleMiddlewareLoadingAction } from 'store/helper';
import dashboardApi from 'apis/dashboard';
const getDashBoardData = createAsyncThunk(
    'get/dashboard',
    (query) => handleMiddlewareLoadingAction(() => dashboardApi.getSlotHistory(query), 'get/dashboard')
)

const deleteInqiry = createAsyncThunk(
    'delete/inqiry',
    (id) => handleMiddlewareLoadingAction(() => dashboardApi.deleteInqiry(id), 'delete/inqiry')
)

const deleteMessage = createAsyncThunk(
    'delete/message',
    (id) => handleMiddlewareLoadingAction(() => dashboardApi.deleteMessage(id), 'delete/message')
)

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState:{
        dashboard: undefined
    },
    extraReducers: builder =>{
        builder.addCase(getDashBoardData.fulfilled, (state, {payload, type}) =>{
            state.dashboard = payload.data
        })
    }
})

export const thunkActions = {
    getDashBoardData,
    deleteInqiry,
    deleteMessage
}

export default dashboardSlice.reducer
