/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-autofocus */
import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, TextField } from '@mui/material';
import ButtonCustom from 'ui-component/button/ButtonCustom';
import { formatNumber } from 'views/utilities/moneyConvert';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { depositMoney } from 'store/modal.slice';
import { setOpenToast, setSeverityAndTitle } from 'ui-component/Toast/toast.slice';
import { useToastControl } from 'views/utilities/toastControl';
import { BtnBox } from './style';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    },
    '@media (max-width: 540px)': {
        '.MuiBox-root': {
            minWidth: '100%'
        },
        
    }
}))

// eslint-disable-next-line react/prop-types
export default function ModaRecharge({ open, setOpen, me }) {
    const [value, setValue] = React.useState(0)
    const dispatch = useDispatch()
    const theme = useTheme()
    const { depositStatus } = useSelector(state => state.modalReducer)
    const { toastControl } = useToastControl();
    const handleClose = () => {
        setOpen(false)
    }

    const handleInputChange = (e) => {
        const rawValue = e;
        const numericValue = parseFloat(rawValue.replace(/,/g, '')) || 0;
        setValue(numericValue);

    };
    useEffect(() => [me])
    const handleDeposit = () => {
        if (value > 0) {
            dispatch(depositMoney({ money: value }))
        }
    }
    useEffect(() => {
        if(depositStatus){
            if (depositStatus?.status === 200) {
                toastControl(true, 'success', depositStatus?.message)
                setOpen(false)
            } if(depositStatus?.status !== 200) {
                toastControl(true, 'error', depositStatus?.message)
            }
        }  
    }, [depositStatus])

    return (
        <>
            {me.data &&
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby='responsive-dialog-title'
                    open={open}
                >
                    <Typography
                        variant='h3'
                        sx={{ m: 0, p: 2 }}
                        id='customized-dialog-title'
                    >
                        충전신청
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ padding: '40px', minWidth: '600px' }}>
                        <Typography variant='h4' sx={{ m: 0, p: 2 }}>
                            입금 계좌정보:
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                variant='h5'
                                sx={{ m: 0, p: 2, flexBasis: '50%' }}
                            >
                                은행:
                            </Typography>
                            <Typography variant='h5' sx={{ m: 0, p: 2 }}>
                                {me?.data?.mb_bank_name}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                variant='h5'
                                sx={{ m: 0, p: 2, flexBasis: '50%' }}
                            >
                                계좌번호:
                            </Typography>
                            <Typography variant='h5' sx={{ m: 0, p: 2 }}>
                                {me?.data?.mb_bank_no}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography
                                variant='h5'
                                sx={{ m: 0, p: 2, flexBasis: '50%' }}
                            >
                                예금주명:
                            </Typography>
                            <Typography variant='h5' sx={{ m: 0, p: 2 }}>
                                {me?.data?.mb_bank_own}
                            </Typography>
                        </Box>
                        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button sx={{}} variant='contained'>
                            계좌번호 요청하기
                        </Button>
                    </Box> */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>
                            <Typography
                                component='body1'
                                variant='body1'
                                sx={{ m: 0, p: 0 }}
                                id='customized-dialog-title'
                            >
                                최소입금액:
                            </Typography>
                            <TextField
                                // type="number"
                                placeholder="10만원 이상"
                                id="outlined-read-only-input"
                                value={formatNumber(me?.data?.input_min || 0) || ''}
                                onChange={(e) => {
                                    handleInputChange(e.target.value);
                                }}
                                inputProps={{
                                    disabled: true
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>
                            <Typography
                                component='body1'
                                variant='body1'
                                sx={{ m: 0, p: 0 }}
                                id='customized-dialog-title'
                            >
                                신청 금액:
                            </Typography>
                            <TextField
                                // type="number"
                                placeholder="10만원 이상"
                                id="outlined-read-only-input"
                                value={formatNumber(value) || ''}
                                onChange={(e) => {
                                    handleInputChange(e.target.value);
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <BtnBox
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography
                                    component='body1'
                                    variant='body1'
                                    sx={{ m: 0, p: 0 }}
                                    id='customized-dialog-title'
                                >
                                    예금주명:
                                </Typography>
                                <BtnBox sx={{ display: 'flex', gap: '5px', my: 1 }}>
                                    <ButtonCustom
                                        title="100만원"
                                        onClick={() => {
                                            setValue(1000000);
                                        }}
                                    />
                                    <ButtonCustom title="50만원" onClick={() => setValue(500000)} />
                                    <ButtonCustom title="10만원" onClick={() => setValue(100000)} />
                                    <ButtonCustom title="5만원" onClick={() => setValue(50000)} />
                                    <ButtonCustom title="1만원" onClick={() => setValue(10000)} />
                                    <ButtonCustom
                                        title="정정
                                "
                                        onClick={() => setValue(0)}
                                    />
                                </BtnBox>
                            </BtnBox>
                            <TextField
                                id='outlined-read-only-input'
                                defaultValue={me?.data?.mb_id}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Box>
                    </Box>

                    <DialogActions sx={{ margin: '0 auto' }}>
                        <Button
                            autoFocus
                            sx={{
                                color: '#fff',
                                backgroundColor: '#844fc1',
                                borderColor: '#844fc1',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                                '&:hover': {
                                    backgroundColor: '#5f3491',
                                    borderColor: '#844fc1'
                                }
                            }}
                            onClick={handleDeposit}
                        >
                            신청하기
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            }
        </>
    )
}
