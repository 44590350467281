import http from 'views/utilities/http'
const URL = `/upload`

const upload = {
    uploadFile(data) {
        return http.upload(URL, data)
    }
}

export default upload
