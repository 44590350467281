import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Card, Grid, InputAdornment, OutlinedInput, Popper, Button, Typography } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX, IconAlignCenter } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';
import CustomButton from 'views/utilities/CustomButton';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { dispatch } from 'store';
import loaderSlice, { actions } from 'store/loader.slice';
import { handleMiddlewareLoadingAction } from 'store/helper';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,

    '&:hover': {
        background: '#713dad',
        color: theme.palette.secondary.light,
        '#검색': {
            color: '#fff'
        }
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearchButtons = ({ value, setValue, popupState, handleClickOpen, handleClickOpenPay, handleClickOpenChangePoint, handleClickOpenHelp }) => {
    const theme = useTheme();
    const navigate = useNavigate()
    const {loader} = useSelector(state => state.loader)
    const dispatch = useDispatch()
    const changeUrl = () =>{
        dispatch(loaderSlice())
    }
    return (
        <>
            <ButtonBase sx={{ borderRadius: '12px' }} style={{display: 'flex', gap: '5px'}}>
                {/* <HeaderAvatarStyle variant="rounded">
                    <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                </HeaderAvatarStyle> */}
                <CustomButton
                    onClick={() => {
                        navigate('/user-history?type=1')

                    }}
                    bgcolor='#2c73ba'
                    title='보유머니 변동내역'
                    colorText='#fff'
                />
                <CustomButton
                    onClick={handleClickOpen}
                    bgcolor='#6c7293'
                    title='충전신청'
                    colorText='#fff'
                />
                <CustomButton
                    onClick={handleClickOpenPay}
                    bgcolor='#c82333'
                    title='환전신청'
                    colorText='#fff'
                />
                <CustomButton
                    onClick={handleClickOpenChangePoint}
                    bgcolor='#26DAD2'
                    title='포인트 전환'
                    colorText='#fff'
                />
                <CustomButton
                    onClick={
                        handleClickOpenHelp
                    }
                    bgcolor='#713dad'
                    title='1:1상담작성
    '
                    colorText='#fff'
                />
            </ButtonBase>
            <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            // Styles for the first button
                        }}
                        {...bindToggle(popupState)}
                    >
                        <IconX stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
                {/* Add three more buttons similar to the above */}
            </Box>
        </>
    );
};

MobileSearchButtons.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const BtnGroup = ({ handleClickOpen, handleClickOpenPay, handleClickOpenChangePoint, handleClickOpenHelp, openSettlement }) => {
    const theme = useTheme();
    const [value, setValue] = useState('');
    const navigate = useNavigate()
    const {loader} = useSelector(state => state.loader)
    const dispatch = useDispatch()
    const changeUrl = async() =>{
        navigate('/user-history?type=1')
    }
    
    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2,mr: 2 }}>
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                        <IconAdjustmentsHorizontal stroke={1.5} size="1.2rem" />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }} >
                                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <MobileSearchButtons value={value} setValue={setValue} popupState={popupState} handleClickOpen={handleClickOpen}
                                                            handleClickOpenPay={handleClickOpenPay}
                                                            handleClickOpenHelp={handleClickOpenHelp}
                                                            handleClickOpenChangePoint={handleClickOpenChangePoint} />
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block'} }}>
                <Box sx={{ flexGrow: 1 }} className='btn-group'>
                    <CustomButton
                        onClick={changeUrl}
                        bgcolor='#2c73ba'
                        title='보유머니 변동내역'
                        colorText='#fff'
                    />
                    <CustomButton
                        onClick={handleClickOpen}
                        bgcolor='#6c7293'
                        title='충전신청
    '
                        colorText='#fff'
                    />
                    <CustomButton
                        onClick={handleClickOpenPay}
                        bgcolor='#c82333'
                        title='환전신청
'
                        colorText='#fff'
                    />
                    <CustomButton
                        onClick={handleClickOpenChangePoint}
                        bgcolor='#26DAD2'
                        title='포인트 전환'
                        colorText='#fff'
                    />
                    <CustomButton
                        onClick={handleClickOpenHelp}
                        bgcolor='#713dad'
                        title='1:1상담작성
    '
                        colorText='#fff'
                    />
                    <CustomButton
                        onClick={openSettlement}
                        bgcolor='blue'
                        title='정산금 전환
    '
                        colorText='#fff'
                    />
                </Box>
            </Box>
        </>
    );
};

export default BtnGroup;
