import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleMiddlewareLoadingAction } from 'store/helper';
import agentNoteApi from 'apis/agentNote';

const getAgentNoteData = createAsyncThunk(
    'get/agentNote',
    (query) => handleMiddlewareLoadingAction(() => agentNoteApi.getAgentNoteHistory(query), 'get/agentNote')
)
const getAgentNoteDetail = createAsyncThunk(
    'get/noteDetail',
    (query) => handleMiddlewareLoadingAction(() => agentNoteApi.getAgentNoteDetail(query), 'get/noteDetail')
)
const deleteInqiry = createAsyncThunk(
    'delete/inqiry',
    (id) => handleMiddlewareLoadingAction(() => agentNoteApi.deleteInqiry(id), 'delete/inqiry')
)

const deleteMessage = createAsyncThunk(
    'delete/message',
    (id) => handleMiddlewareLoadingAction(() => agentNoteApi.deleteMessage(id), 'delete/message')
)

const agentNoteSlice = createSlice({
    name: 'agentNoteList',
    initialState:{
        noteList: undefined,
        noteDetail: undefined
    },
    reducers: {
        clearDetail: (state) =>{
            state.noteDetail = undefined
        }
    },
    extraReducers: {
        [getAgentNoteData.fulfilled]: (state, action) => {
            state.noteList = action.payload
        },
        [getAgentNoteDetail.fulfilled]: (state, action) => {
                state.noteDetail = action.payload
        }
    }
})

export const thunkActions = {
    getAgentNoteData,
    getAgentNoteDetail,
    deleteInqiry,
    deleteMessage
}
export const actions = agentNoteSlice.actions
export default agentNoteSlice.reducer
