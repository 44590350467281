import { Box, TextField } from "@mui/material";
import styled from "styled-components";

export const BtnBox = styled(Box)`
@media (max-width: 540px){
    flex-wrap: wrap;
}
`
export const BoxMobile = styled(Box)`
@media (max-width: 540px){
    flex-wrap: wrap;
}
`
export const TextFieldCustom = styled(TextField)`
@media (max-width: 540px){
    width: 100%;
}
`